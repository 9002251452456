import { OpenIdConnect } from 'aurelia-open-id-connect';
import { autoinject } from 'aurelia-framework';

@autoinject
export class Unauthorized {
    openIdConnect: OpenIdConnect;

    constructor(openIdConnect: OpenIdConnect) {
        this.openIdConnect = openIdConnect;
    }

    async activate() {
        await this.openIdConnect.login();
    }
}
