import { ImagesModel } from 'images-model';

export class UserModel {
    userId: string = 'NA';
    firstName: string = 'N';
    lastName: string = 'A';
    fullName: string;
    images: ImagesModel;

    constructor(userId: string = 'NA', firstName: string = 'N', lastName: string = 'A', fullName: string = '') {
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.fullName = fullName;
    }
}

export class UserSelectModel {
    selected: boolean = false;
    user: UserModel = null;

    constructor(selected: boolean = false, user: UserModel = null) {
        this.selected = selected;
        this.user = user;
    }
}
