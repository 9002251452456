import { HttpClient, json } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { cleanParamsObject } from 'utils/params';

@autoinject
export class TransactionDetail {
    httpClient: HttpClient;
    id: string;
    json: any;
    router: Router;
    transaction: Transaction;
    props: Object;
    transactionParty: Object;
    transactionPartyProps: Object;

    constructor(httpClient: HttpClient, router: Router) {
        this.httpClient = httpClient;
        this.router = router;
    }

    async activate(params) {
        params = cleanParamsObject(params);
        let extractProps = (object: Object) => {
            return Object.entries(object ?? {})
                .map((x) => <any>{ key: this.captialize(x[0]), value: x[1] })
                .filter((x) => x.key !== 'Properties');
        };

        this.id = params.id;

        try {
            let response: any = await this.httpClient.get(`transactions/${this.id}`);
            let json = await response.json();

            this.json = JSON.stringify(json, null, 2);
            this.transaction = <Transaction>json;

            this.props = extractProps(this.transaction.properties);
            this.transactionPartyProps = extractProps(this.transaction.transactionParty?.properties);
        } catch (error) {
            alert(error.message);
            this.router.navigateToRoute('transactions', {});
        }
    }

    captialize(str: string) {
        return str.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2);
    }
}

class Transaction {
    amount: number;
    transactionParty;
    transactionPartyProps;
    properties;
}
